// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {jBIXoysoJ: {hover: true, pressed: true}};

const cycleOrder = ["jBIXoysoJ", "zFvPCBvlh"];

const serializationHash = "framer-9b28q"

const variantClassNames = {jBIXoysoJ: "framer-v-1jo2sc8", zFvPCBvlh: "framer-v-1t2p1qn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "jBIXoysoJ", Mobile: "zFvPCBvlh"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, KM0Pp0A4y: tap ?? props.KM0Pp0A4y, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jBIXoysoJ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KM0Pp0A4y, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jBIXoysoJ", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap10nb5hf = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (KM0Pp0A4y) {
const res = await KM0Pp0A4y(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "augiA20Il"}} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1jo2sc8", className, classNames)} framer-wf4el`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"jBIXoysoJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap10nb5hf} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"jBIXoysoJ-hover": {opacity: 0.8}, "jBIXoysoJ-pressed": {opacity: 0.72}}} {...addPropertyOverrides({"jBIXoysoJ-hover": {"data-framer-name": undefined}, "jBIXoysoJ-pressed": {"data-framer-name": undefined}, zFvPCBvlh: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-u1nt7y"} layoutDependency={layoutDependency} layoutId={"sWX4e9dfk"}><SVG className={"framer-kc2zmi"} data-framer-name={"Frame 320"} fill={"rgba(0,0,0,1)"} intrinsicHeight={20} intrinsicWidth={60} layoutDependency={layoutDependency} layoutId={"n3bWj1KsQ"} svg={"<svg width=\"60\" height=\"20\" viewBox=\"0 0 60 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_36_5143)\">\n<path d=\"M1 10C3.4 -2 5.8 -2 8.2 10C10.6 22 13 22 15.4 10C17.8 -2 20.2 -2 22.6 10C25 22 27.4 22 29.8 10C32.2 -2 34.6 -2 37 10C39.4 22 41.8 22 44.2 10C46.6 -2 49 -2 51.4 10C53.8 22 56.2 22 58.6 10\" stroke=\"white\" stroke-linecap=\"round\"/>\n<circle cx=\"58.8471\" cy=\"7.84706\" r=\"0.847059\" fill=\"white\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_36_5143\">\n<rect width=\"60\" height=\"20\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n"} withExternalLayout/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9b28q[data-border=\"true\"]::after, .framer-9b28q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9b28q.framer-wf4el, .framer-9b28q .framer-wf4el { display: block; }", ".framer-9b28q.framer-1jo2sc8 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 6px 0px 6px 12px; position: relative; text-decoration: none; width: 208px; }", ".framer-9b28q .framer-u1nt7y { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1px; }", ".framer-9b28q .framer-kc2zmi { flex: none; height: 16px; position: relative; width: 48px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9b28q.framer-1jo2sc8, .framer-9b28q .framer-u1nt7y { gap: 0px; } .framer-9b28q.framer-1jo2sc8 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-9b28q.framer-1jo2sc8 > :first-child, .framer-9b28q .framer-u1nt7y > :first-child { margin-left: 0px; } .framer-9b28q.framer-1jo2sc8 > :last-child, .framer-9b28q .framer-u1nt7y > :last-child { margin-right: 0px; } .framer-9b28q .framer-u1nt7y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"zFvPCBvlh":{"layout":["fixed","auto"]},"HpLaclB5x":{"layout":["fixed","auto"]},"OwXAH7Ikr":{"layout":["fixed","auto"]}}}
 * @framerVariables {"KM0Pp0A4y":"tap"}
 * @framerImmutableVariables true
 */
const Framerhuf04LQWQ: React.ComponentType<Props> = withCSS(Component, css, "framer-9b28q") as typeof Component;
export default Framerhuf04LQWQ;

Framerhuf04LQWQ.displayName = "Component/Brand";

Framerhuf04LQWQ.defaultProps = {height: 28, width: 208};

addPropertyControls(Framerhuf04LQWQ, {variant: {options: ["jBIXoysoJ", "zFvPCBvlh"], optionTitles: ["Default", "Mobile"], title: "Variant", type: ControlType.Enum}, KM0Pp0A4y: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerhuf04LQWQ, [])